import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { InvoiceStatus } from "../module/telegram-api-controller/telegram-api.constants";
import telegramApiController from "../module/telegram-api-controller";
import envConfig from "../api/config";
import { $token, authFx } from "./auth";
import { $activeEvent } from "./citybuilder";
import restClient from "../api/rest-client";
import { TournamentType } from "./tournaments/tourmanets.interfaces";

// Enums
export enum InvoiceType {
  BeautifyCityEvent = "beutify-city-event",
  BuyTurboBoost = "buy-turbo-boost",
  BuyRefillEnergy = "buy-refill-energy",
  DoubleTurboTaps = "double-turbo-taps",
  DoublePassiveIncome = "double-passive-income",
  DoubleFriendsIncome = "double-friends-income",
  BuyFriend = "buy-friend",
  MovesDoubleClaim = "moves-double-claim",
  LotteriesBuyTicket = "lotteries-buy-ticket",
  MovesOpenEvent = "moves-open-event",
}

interface TotalUserSpent {
  totalSpent: number;
  spentOnLotteries: number;
  spentOnBoosts: number;
  spentOnFriends: number;
  spentOnClicker: number;
}

export interface PriceModifier {
  discount: number;
  max_amount?: number;
  min_amount?: number;
  price: number;
  type: "per_unit" | "fixed";
}

export const InvoiceTypeInitialValues = {
  [InvoiceType.BeautifyCityEvent]: {
    bear_market: 0,
    blockchain_conference: 0,
    bull_market: 0,
    cyber_defense_drill: 0,
    hacker_attack: 0,
    new_cryptocurrency_launch: 0,
    technological_breakthrough: 0,
  },
  [InvoiceType.BuyTurboBoost]: 0,
  [InvoiceType.BuyRefillEnergy]: 0,
  [InvoiceType.DoubleTurboTaps]: 0,
  [InvoiceType.DoublePassiveIncome]: 0,
  [InvoiceType.DoubleFriendsIncome]: 0,
  [InvoiceType.BuyFriend]: [
    {
      discount: 0,
      max_amount: 4,
      price: 0,
      type: "per_unit",
    } as PriceModifier,
  ],
  [InvoiceType.MovesDoubleClaim]: [
    {
      discount: 0,
      price: 0,
      type: "fixed",
    } as PriceModifier,
  ],
  [InvoiceType.LotteriesBuyTicket]: {
    [TournamentType.DailyFree]: [
      {
        discount: 0,
        price: 0,
        type: "per_unit",
      } as PriceModifier,
    ],
    [TournamentType.DailyPaid]: [
      {
        discount: 0,
        price: 0,
        type: "per_unit",
      } as PriceModifier,
    ],
    [TournamentType.WeeklyPaid]: [
      {
        discount: 0,
        price: 0,
        type: "per_unit",
      } as PriceModifier,
    ],
  },
};

type InvoiceDoneStatus = "success" | "error";

// Stores
export const $invoiceStatus = createStore<InvoiceStatus | null>(null);
export const $invoiceType = createStore<InvoiceType | null>(null);
export const $paymentId = createStore<string | null>(null);
export const $invoiceDoneStatus = createStore<InvoiceDoneStatus | null>(null);
export const $starPricesByKey = createStore(InvoiceTypeInitialValues);
export const $invoiceLoading = createStore(false);
export const $userStarsTotalSpent = createStore<TotalUserSpent>({
  totalSpent: 0,
  spentOnLotteries: 0,
  spentOnBoosts: 0,
  spentOnFriends: 0,
  spentOnClicker: 0,
});

// Events
export const setInvoiceStatus = createEvent<{ status: string }>();
export const startBeautifyCityEventInvoice = createEvent();
export const startBuyTurboBoostInvoice = createEvent();
export const startBuyRefillEnergyInvoice = createEvent();
export const getUserStarsTotalSpent = createEvent();
export const startDoubleTurboTapsInvoice = createEvent<number>();
export const startDoublePassiveIncomeInvoice = createEvent<number>();
export const startDoubleFriendsIncomeInvoice = createEvent<number>();
export const startBuyFriendInvoice = createEvent<number>();
export const startLotteriesBuyTicketInvoice = createEvent<{
  amount: number;
  id: string;
}>();
export const startDoubleMovesClaimInvoice = createEvent<{
  amount: number;
  id: string;
}>();
export const startMovesOpenEventInvoice = createEvent<{ id: string }>();

export const finishInvoiceAction = createEvent();
export const setPaymentId = createEvent<string>();

export const cityEventPrice = combine(
  $starPricesByKey,
  $activeEvent,
  (starPricesByKey, activeEvent) => {
    const eventName =
      activeEvent?.id as keyof (typeof InvoiceTypeInitialValues)[InvoiceType.BeautifyCityEvent];
    if (!eventName) return 0;
    return starPricesByKey[InvoiceType.BeautifyCityEvent][eventName];
  },
);

const findPriceModifier = (
  amount: number,
  priceModifiers: PriceModifier[],
): PriceModifier | null => {
  let found = false;
  let iter = 0;
  let valueToReturn: PriceModifier | undefined = undefined;
  while (!found) {
    const modifier = priceModifiers[iter];

    if (!modifier) {
      valueToReturn = priceModifiers[priceModifiers.length - 1];
      found = true;
    }
    const minAmount = modifier?.min_amount ?? 0;
    const maxAmount = modifier?.max_amount ?? Infinity;
    if (amount >= minAmount && amount < maxAmount) {
      valueToReturn = modifier;
      found = true;
    }
    iter++;
  }

  if (!valueToReturn) {
    valueToReturn = priceModifiers[0];
  }
  if (!valueToReturn) return null;
  return valueToReturn;
};

export const getPriceByModifer = (
  amount: number,
  key: InvoiceType,
  subType?: string,
): PriceModifier | number | null => {
  switch (key) {
    case InvoiceType.LotteriesBuyTicket: {
      const priceModifiers =
        $starPricesByKey.getState()[InvoiceType.LotteriesBuyTicket][
          subType as TournamentType
        ];
      if (!priceModifiers) return null;
      return findPriceModifier(amount, priceModifiers);
    }
    case InvoiceType.BuyFriend: {
      const priceModifiers = $starPricesByKey.getState()[InvoiceType.BuyFriend];
      if (!priceModifiers) return null;

      return findPriceModifier(amount, priceModifiers);
    }
    case InvoiceType.MovesDoubleClaim: {
      const priceModifiers =
        $starPricesByKey.getState()[InvoiceType.MovesDoubleClaim];
      if (!priceModifiers) return null;
      let price: number = 0;
      let found = false;
      let iter = 0;
      while (!found) {
        const minAmount = priceModifiers[iter].min_amount ?? 0;
        const maxAmount = priceModifiers[iter].max_amount ?? Infinity;
        const priceInIter = priceModifiers[iter];
        if (amount >= minAmount && amount < maxAmount) {
          price = priceInIter.price;
          found = true;
        }
        iter++;
      }

      return price;
    }
    default:
      return null;
  }
};

// Interfaces
export interface EventResponse {
  invoiceLink: string;
  paymentId: string;
}

export interface DoubleMoneyEventPayload {
  amount: number;
}

export interface GetStatusPayload {
  paymentId: string;
}

export interface StatusResponse {
  id: string;
  status: string;
  goods_delivered: boolean;
}

// Constants
export const STATUS_URL = "/stars/status";

// Effects
export const getInvoiceUrlFx = createEffect(
  async ({
    type,
    token,
    payload,
  }: {
    type: InvoiceType;
    token: string;
    payload: { [key: string]: unknown };
  }) => {
    const fetchPayload: {
      method: string;
      headers: {
        "Content-Type": string;
        Authorization: string;
      };
      body?: string;
    } = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    if (payload) {
      fetchPayload.body = JSON.stringify(payload);
    }
    const response = await fetch(
      `${envConfig.apiUrl}/stars/${type}`,
      fetchPayload,
    );

    const data: EventResponse = await response.json();
    return data;
  },
);

export const startInvoiceFx = createEffect(async (url: string) => {
  telegramApiController.openInvoice(url, (status) =>
    setInvoiceStatus({ status }),
  );
});

interface PaymentStatusData {
  payment: {
    id: "string";
    status: "pending";
    goods_delivered: false;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getStarsPricesFx = createEffect(async (_: { token: string }) => {
  const data: { prices: typeof InvoiceTypeInitialValues } =
    await restClient.post("stars/config");

  return data.prices;
});

const getUserStarsTotalSpentFx = createEffect(async () => {
  const data: TotalUserSpent = await restClient.post("stars/user-stats");
  return data;
});

export const getPaymentStatusFx = createEffect<
  { token: string; paymentId: string },
  PaymentStatusData | undefined
>(async ({ paymentId }) => {
  let data: PaymentStatusData | undefined;
  const poll = async (
    notFirstAttempt: boolean,
  ): Promise<PaymentStatusData | undefined> => {
    if (!notFirstAttempt) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
    const responseParsed: PaymentStatusData = await restClient.post(
      "stars/status",
      JSON.stringify({ paymentId }),
    );

    if (responseParsed?.payment.goods_delivered) {
      return data;
    } else {
      return poll(true);
    }
  };

  const returnValue = await poll(false);
  return returnValue;
});

sample({
  clock: getUserStarsTotalSpent,
  target: getUserStarsTotalSpentFx,
});

sample({
  clock: getUserStarsTotalSpentFx.doneData,
  fn: (data) => data,
  target: $userStarsTotalSpent,
});

sample({
  clock: authFx.doneData,
  fn: ({ token }) => ({ token }),
  target: [getStarsPricesFx],
});

sample({
  clock: getStarsPricesFx.doneData,
  fn: (data) => {
    return data;
  },
  target: $starPricesByKey,
});

sample({
  clock: getPaymentStatusFx.doneData,
  fn: () => "success" as InvoiceDoneStatus,
  target: $invoiceDoneStatus,
});

// Samples
sample({
  clock: startBeautifyCityEventInvoice,
  fn: () => InvoiceType.BeautifyCityEvent,
  target: $invoiceType,
});

sample({
  clock: startBuyFriendInvoice,
  fn: () => InvoiceType.BuyFriend,
  target: $invoiceType,
});

sample({
  clock: startLotteriesBuyTicketInvoice,
  fn: () => InvoiceType.LotteriesBuyTicket,
  target: $invoiceType,
});

sample({
  clock: startDoubleMovesClaimInvoice,
  fn: () => InvoiceType.MovesDoubleClaim,
  target: $invoiceType,
});

sample({
  clock: startMovesOpenEventInvoice,
  fn: () => InvoiceType.MovesOpenEvent,
  target: $invoiceType,
});

sample({
  clock: startBuyTurboBoostInvoice,
  fn: () => InvoiceType.BuyTurboBoost,
  target: $invoiceType,
});

sample({
  clock: startBuyRefillEnergyInvoice,
  fn: () => InvoiceType.BuyRefillEnergy,
  target: $invoiceType,
});

sample({
  clock: startDoubleTurboTapsInvoice,
  fn: () => InvoiceType.DoubleTurboTaps,
  target: $invoiceType,
});

sample({
  clock: startDoublePassiveIncomeInvoice,
  fn: () => InvoiceType.DoublePassiveIncome,
  target: $invoiceType,
});

sample({
  clock: startDoubleFriendsIncomeInvoice,
  fn: () => InvoiceType.DoubleFriendsIncome,
  target: $invoiceType,
});

sample({
  source: $token,
  clock: startBeautifyCityEventInvoice,
  fn: (token) => ({
    token,
    payload: {},
    type: InvoiceType.BeautifyCityEvent,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startBuyTurboBoostInvoice,
  fn: (token) => ({
    token,
    payload: {},
    type: InvoiceType.BuyTurboBoost,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startBuyRefillEnergyInvoice,
  fn: (token) => ({
    token,
    payload: {},
    type: InvoiceType.BuyRefillEnergy,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startDoubleTurboTapsInvoice,
  fn: (token, amount) => ({
    token,
    payload: { amount },
    type: InvoiceType.DoubleTurboTaps,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startBuyFriendInvoice,
  fn: (token, amount) => ({
    token,
    payload: { amount },
    type: InvoiceType.BuyFriend,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startLotteriesBuyTicketInvoice,
  fn: (token, { amount, id }) => ({
    token,
    payload: { amount, lotteryId: id },
    type: InvoiceType.LotteriesBuyTicket,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startDoubleMovesClaimInvoice,
  fn: (token, { amount, id }) => ({
    token,
    payload: { amount, moveId: id },
    type: InvoiceType.MovesDoubleClaim,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startMovesOpenEventInvoice,
  fn: (token, { id }) => ({
    token,
    payload: { moveId: id },
    type: InvoiceType.MovesOpenEvent,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startDoublePassiveIncomeInvoice,
  fn: (token, amount) => ({
    token,
    payload: { amount },
    type: InvoiceType.DoublePassiveIncome,
  }),
  target: getInvoiceUrlFx,
});

sample({
  source: $token,
  clock: startDoubleFriendsIncomeInvoice,
  fn: (token, amount) => ({
    token,
    payload: { amount },
    type: InvoiceType.DoubleFriendsIncome,
  }),
  target: getInvoiceUrlFx,
});

sample({
  clock: getInvoiceUrlFx.doneData,
  fn: ({ invoiceLink }) => invoiceLink,
  target: [startInvoiceFx],
});

sample({
  clock: getInvoiceUrlFx.doneData,
  fn: ({ paymentId }) => paymentId,
  target: $paymentId,
});

sample({
  clock: getInvoiceUrlFx.failData,
  fn: () => false,
  target: [finishInvoiceAction],
});

sample({
  source: setInvoiceStatus,
  clock: setInvoiceStatus,
  fn: ({ status }) => status as InvoiceStatus,
  target: $invoiceStatus,
}).watch((status) => {
  switch (status) {
    case InvoiceStatus.Paid:
      getPaymentStatusFx({
        token: $token.getState(),
        paymentId: $paymentId.getState() as string,
      });
      break;
    case InvoiceStatus.Cancelled:
    case InvoiceStatus.Failed:
      finishInvoiceAction();
      break;
    default:
      break;
  }
});

sample({
  clock: finishInvoiceAction,
  target: [
    $invoiceStatus.reinit,
    $invoiceType.reinit,
    $paymentId.reinit,
    $invoiceDoneStatus.reinit,
    $invoiceLoading.reinit,
  ],
});

sample({
  clock: [
    startBeautifyCityEventInvoice,
    startBuyTurboBoostInvoice,
    startBuyRefillEnergyInvoice,
    startDoubleTurboTapsInvoice,
    startDoublePassiveIncomeInvoice,
    startBuyFriendInvoice,
    startDoubleFriendsIncomeInvoice,
    startLotteriesBuyTicketInvoice,
    startMovesOpenEventInvoice,
  ],
  fn: () => true,
  target: $invoiceLoading,
});

sample({
  clock: setPaymentId,
  target: $paymentId,
});
