import { TurboTapState } from "../../state/clicker";
import { ClickerState, User } from "../../state/types";

export enum StorageToUse {
  CloudStorage = "cloudStorage",
  LocalStorage = "localStorage",
}

export enum StorageKeys {
  Token = "token",
  Lang = "lang",
  User = "user",
  ClickerStateLastSync = "clickerState_lastSync",
  OnboardClicksCount = "onboard_clicks_count",
  OnboardStatus = "onboard_status",
  LastTickData = "last_tick_data",
  StorageData = "storage_data",
  CurrentTurboTap = "current_turbo_tap",
  LastTurboTap = "last_turbo_tap",
  OnboardStartDate = "onboard_start_date",
  OnboardTournamentClicked = "onboard_tournament_clicked",
}

export interface SyncStorageData {
  lastRecource: StorageToUse;
  lastChangeDate: string;
}

export type SetItemType =
  | TurboTapState
  | SyncStorageData
  | ClickerState
  | TurboTapState
  | User;
