import { NavigateFunction } from "react-router-dom";
import telegramApiController from "../module/telegram-api-controller";

export const backButtonEnable = (navigate: NavigateFunction) => {
  window.Telegram.WebApp.BackButton.show();
  window.Telegram.WebApp.BackButton.onClick(() => {
    window.Telegram.WebApp.BackButton.hide();
    navigate("/game");
  });
};

export const lightTgTap = () => {
  telegramApiController.hapticFeedbackImpact("light");
  if ("vibrate" in navigator) {
    navigator.vibrate(50);
  }
};
