import envConfig from "./config";

type AdditionalProps = Omit<RequestInit, "method" | "headers" | "body">;

export class BaseRestClient {
  private baseUrl: string;

  private token: string = "";

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken = (token: string) => {
    this.token = token;
  };

  private request = async ({
    url,
    method,
    body,
    headers = {},
    ...additionalProps
  }: AdditionalProps & {
    url: string;
    method: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any;
    headers?: Record<string, string>;
  }) => {
    const payload: RequestInit = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
        ...headers,
      },
      ...additionalProps,
    };
    if (body) {
      payload.body = body;
    }
    const response = await fetch(`${this.baseUrl}/${url}`, payload);

    const data = await response.json();
    if (data.error) {
      throw new Error(data.error);
    }
    return data;
  };

  //   get = async (url: string, additionalProps: AdditionalProps = {}) => {
  //     return await this.request({ url, method: "GET", ...additionalProps });
  //   };

  post = async (
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any,
    additionalProps: AdditionalProps = {},
  ) => {
    return await this.request({
      url,
      method: "POST",
      body,
      ...additionalProps,
    });
  };

  //   delete = async (url: string) => {
  //     return await this.request({ url, method: "DELETE" });
  //   };
}

export default new BaseRestClient(envConfig.apiUrl);
