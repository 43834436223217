import { createEffect, createEvent, sample } from "effector";
import { ClickerState, DailyRewardDayState } from "./types";
import { $token } from "./auth";

import restClient from "../api/rest-client";
import { amplitudeController } from "../module/amplitude";

export const claimDailyReward = createEvent();
export const claimDailyRewardFx = createEffect<string, ClickerState>(
  async () => {
    const result = await restClient.post(
      "clicker/claim-daily-reward",
      JSON.stringify({}),
    );
    return result.clickerState;
  },
);

sample({
  source: $token,
  clock: claimDailyReward,
  target: claimDailyRewardFx,
});

claimDailyRewardFx.doneData.watch((clickerState) => {
  const rewardReversed: DailyRewardDayState[] = [];
  clickerState.dailyRewardState.forEach((item) => rewardReversed.unshift(item));
  const lastClaimed = rewardReversed.find(
    ({ isClaimed }) => isClaimed,
  ) as DailyRewardDayState;
  amplitudeController.trackDailyBonusClaimed({
    day_number: lastClaimed.dayNumber,
    reward: `${lastClaimed?.bonusCoins} coins`,
  });
});
