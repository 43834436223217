// Enum for Amplitude Events
export enum AmplitudeEvents {
  TriumphZoneScreenViewed = "triumph_zone_screen_viewed",
  TriumphDrawViewed = "triumph_draw_viewed",
  TriumphInsufficientTickets = "triumph_insufficient_tickets",
  TriumphRedirectToMoves = "triumph_redirect_to_moves",
  TriumphTicketPurchased = "triumph_ticket_purchased",
  TriumphParticipation = "triumph_participation",
  TriumphResult = "triumph_result",
  MovesScreenViewed = "moves_screen_viewed",
  MoveSelected = "move_selected",
  MoveBetPlaced = "move_bet_placed",
  MoveResult = "move_result",
  MoveClosed = "move_closed",
  CityTaskScreenViewed = "city_task_screen_viewed",
  CityTaskStarted = "city_task_started",
  CityTaskCompleted = "city_task_completed",
  CityTaskGoToBuilding = "city_task_go_to_building",
  DailyBonusScreenViewed = "daily_bonus_screen_viewed",
  DailyBonusClaimed = "daily_bonus_claimed",
  DailyBonusError = "daily_bonus_error",
  OnboardingScreenViewed = "onboarding_screen_viewed",
  OnboardingAction = "onboarding_action",
  OnboardingCompleted = "onboarding_completed",
}
