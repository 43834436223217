import { createEffect, createEvent, createStore, sample } from "effector";

import { ClickerState, User, UserData } from "./types.ts";

import envConfig from "../api/config.ts";
import appStorage from "../module/app-storage/index.ts";
import { StorageKeys } from "../module/app-storage/app-storage.constants.ts";
import restClient from "../api/rest-client.ts";
import { $tickSyncCount, clearTickSyncCall, tick } from "./state.ts";
import { fetchWithdrawBalanceFx } from "./tournaments/tournaments.store.ts";
import { RoutePaths } from "../router/routes.ts";
import { router } from "../router/router.tsx";

const DEV_INIT_DATA = import.meta.env.VITE_DEV_INIT_DATA;

const startAppNamesMap = {
  moves: RoutePaths.GameMoves,
  tournaments: RoutePaths.GameTournaments,
  game: RoutePaths.Game,
};

export const $token = createStore("");
export const $user = createStore<User | null>(null);
export const $clickerState = createStore<ClickerState | null>(null);
export const $earned = createStore<number | null>(null);
export const $isAuthLoading = createStore(false);
export const $isSyncLoading = createStore(false);
export const $listenersAdded = createStore(false);
export const $startPage = createStore<string | null>(null);
const $isInitialized = createStore(false);

export const $rankUpPropsByEarh = createStore({
  show: false,
  currentRank: 0,
  canChangeState: true,
});

export const resizeRequest = createEvent();
export const resetRequestDate = createEvent();
export const callLastSyncFx = createEvent();
export const addListeners = createEvent();
export const setMovesParticipationsCount = createEvent<number>();
export const setStartPage = createEvent<string>();

sample({
  clock: addListeners,
  fn: () => true,
  target: $listenersAdded,
});

sample({
  source: {
    clickerState: $clickerState,
  },
  clock: resetRequestDate,
  fn: ({ clickerState }) => {
    if (clickerState === null) return null;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { requestDate, ...rest } = clickerState;
    return rest;
  },
  target: $clickerState,
});

const setRankUpByEarn = createEvent<{
  currentRank: number;
  show: boolean;
}>();

export const resetRankUpByEarn = createEvent();

sample({
  clock: resetRankUpByEarn,
  fn: () => ({
    show: false,
    currentRank: 0,
    canChangeState: false,
  }),
  target: $rankUpPropsByEarh,
});

$rankUpPropsByEarh.on(
  setRankUpByEarn,
  (currentState, { currentRank, show }) => {
    if (!currentState.canChangeState) {
      return {
        currentRank: -1,
        show: false,
        canChangeState: false,
      };
    }
    return {
      currentRank: currentRank - 1,
      show,
      canChangeState: false,
    };
  },
);

export const $dailyBonusClaimed = $clickerState.map((clickerState) => {
  if (clickerState === null || !clickerState.dailyRewardState) return false;
  const dailyClaimed =
    clickerState?.dailyRewardState.filter((day) => day.isClaimed) || [];
  if (dailyClaimed.length === clickerState?.dailyRewardState.length) {
    return true;
  }
  const index = clickerState?.dailyRewardState.findIndex(
    (day) => !day.isAvailable && !dailyClaimed.includes(day),
  );
  return index && index !== -1
    ? clickerState?.dailyRewardState[index - 1].isClaimed
    : false;
});
export const setEarn = createEvent<number>();
export const resetEarn = createEvent();

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const authFx = createEffect<void, UserData>(async () => {
  const initData =
    import.meta.env.MODE === "development"
      ? DEV_INIT_DATA
      : window.Telegram.WebApp.initData;

  const response = await fetch(`${envConfig.apiUrl}/auth/account-info`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: initData,
    }),
  });

  const data = await response.json();
  restClient.setToken(data.token);
  return data;
});

export const handleBeacon = async () => {
  restClient.post("clicker/beacon", {}, { keepalive: true });
};

interface SyncFxResponse {
  clickerState: ClickerState;
  splineUrl: string;
}

interface ExtendedSyncFxResponse extends SyncFxResponse {
  earnedData: {
    earned: number;
    requestsSinceBeacon: number;
    beaconTimestamp: number;
  };
}

export const lastSyncFx = createEffect<string, ExtendedSyncFxResponse>(
  async () => {
    const requestDate = new Date();

    const { splineUrl, clickerState, earnedData }: ExtendedSyncFxResponse =
      await restClient.post("clicker/earned-since-last-beacon");

    return {
      splineUrl,
      earnedData,
      clickerState: {
        ...clickerState,
        requestDate,
      },
    };
  },
);

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const syncFx = createEffect<void, SyncFxResponse>(async () => {
  const requestDate = new Date();
  const { splineUrl, clickerState }: SyncFxResponse =
    await restClient.post("clicker/sync");

  return {
    splineUrl,
    clickerState: {
      ...clickerState,
      requestDate,
    },
  };
});

sample({
  clock: authFx.pending,
  target: $isAuthLoading,
});
sample({
  clock: syncFx.pending,
  target: $isSyncLoading,
});

sample({
  source: {
    token: $token,
    isInitialized: $isInitialized,
  },
  clock: callLastSyncFx,
  filter: ({ token, isInitialized }) => {
    return !!token && isInitialized;
  },
  fn: ({ token }) => token,
  target: lastSyncFx,
});

export const callSyncFx = createEvent();

sample({
  clock: callSyncFx,
  target: syncFx,
});

sample({
  clock: authFx.doneData,
  fn: ({ token }) => token,
  target: $token,
});
sample({
  clock: authFx.doneData,
  fn: ({ user }) => user,
  target: $user,
});
sample({
  clock: authFx.doneData,
  fn: ({ token }) => token,
  target: [lastSyncFx],
});

sample({
  clock: setEarn,
  fn: (value) => (value > 0 && value < 1 ? 0 : value),
  target: $earned,
});

sample({
  clock: resetEarn,
  target: $earned.reinit,
});

sample({
  source: $tickSyncCount,
  clock: tick,
  filter: (count) => count === 120,
  fn: () => null,
  target: [clearTickSyncCall, syncFx],
});

authFx.doneData.watch(({ token, user }) => {
  appStorage.setItem(StorageKeys.Token, token);
  appStorage.setItem(StorageKeys.User, user);
  fetchWithdrawBalanceFx();
  const initData =
    import.meta.env.MODE === "development"
      ? DEV_INIT_DATA
      : window.Telegram.WebApp.initData;
  const initDataAsSearchParams = new URLSearchParams(initData);
  const startParam = initDataAsSearchParams.get("start_param");
  if (
    startParam &&
    startAppNamesMap[startParam as keyof typeof startAppNamesMap]
  ) {
    router.navigate(
      startAppNamesMap[startParam as keyof typeof startAppNamesMap],
    );
  }
});

syncFx.failData.watch((error) => {
  console.log("syncFx fail", error);
});

sample({
  clock: lastSyncFx.doneData,
  fn: () => true,
  target: $isInitialized,
});

sample({
  source: $clickerState,
  filter: (clickerState) => !!clickerState,
  clock: setMovesParticipationsCount,
  fn: (clickerState, value) =>
    ({
      ...clickerState,
      movesParticipationsCount: value,
    }) as ClickerState,
  target: $clickerState,
});

lastSyncFx.doneData.watch(({ clickerState, earnedData }) => {
  const lastSync = appStorage.getItem(
    StorageKeys.ClickerStateLastSync,
  ) as unknown as ClickerState;

  if (earnedData && earnedData.earned > 0) {
    setEarn(earnedData.earned);
  }

  if (
    lastSync &&
    clickerState.balanceCoins > lastSync.coinsRequiredForNextRank
  ) {
    setRankUpByEarn({
      show: true,
      currentRank: lastSync.rankLevel,
    });
  }

  if (clickerState) {
    appStorage.setItem(StorageKeys.ClickerStateLastSync, clickerState);
  }
});
