import {
  MainPageLotteriesList,
  TournamentType,
  TicketsBalance,
  TournamentDetails,
} from "./tourmanets.interfaces";

export const mainPageListDefaultValue: MainPageLotteriesList = {
  [TournamentType.DailyFree]: [],
  [TournamentType.DailyPaid]: [],
  [TournamentType.WeeklyPaid]: [],
};

export const ticketsDefaultValue: TicketsBalance = {
  [TournamentType.DailyFree]: 0,
  [TournamentType.DailyPaid]: 0,
  [TournamentType.WeeklyPaid]: 0,
};

export const tournamentDetailsDefaultValue: TournamentDetails = {
  id: "",
  entryPrice: 0,
  title: "",
  endsAt: 0,
  startsAt: 0,
  imageUrl: "",
  prizeFund: 0,
  type: TournamentType.DailyFree,
  status: "",
  winnersSelected: false,
  claimable: false,
  userTicketsCount: 0,
  userWonAmount: "",
  totalParticipantsCount: 0,
  totalTicketsCount: 0,
  winners: [],
};
