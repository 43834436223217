import * as amplitude from "@amplitude/analytics-browser";
import { AmplitudeEvents } from "./constants";
import {
  InitDataObject,
  TriumphDrawViewedData,
  TriumphInsufficientTicketsData,
  TriumphRedirectToMovesData,
  TriumphTicketPurchasedData,
  TriumphParticipationData,
  TriumphResultData,
  MovesScreenViewedData,
  MoveSelectedData,
  MoveBetPlacedData,
  MoveResultData,
  MoveClosedData,
  CityTaskScreenViewedData,
  CityTaskStartedData,
  CityTaskCompletedData,
  CityTaskGoToBuildingData,
  DailyBonusScreenViewedData,
  DailyBonusClaimedData,
  DailyBonusErrorData,
  OnboardingScreenViewedData,
  OnboardingActionData,
  OnboardingCompletedData,
} from "./amplitude.contract";

const DEV_INIT_DATA = import.meta.env.VITE_DEV_INIT_DATA;

class AmplitudeController {
  initAmplitude = () => {
    const initData =
      import.meta.env.MODE === "development"
        ? DEV_INIT_DATA
        : window.Telegram.WebApp.initData;

    const initDataObject: InitDataObject = {
      user: {
        id: "",
        first_name: "",
        last_name: "",
        username: "",
        language_code: "",
        is_premium: false,
        allows_write_to_pm: false,
      },
      auth_date: "",
      chat_instance: "",
      chat_type: "",
      hash: "",
      start_param: "",
    };
    const initDataAsSearchParams = new URLSearchParams(initData);
    initDataAsSearchParams.forEach((value, key) => {
      if (key === "user") {
        initDataObject[key] = JSON.parse(value);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initDataObject[key as keyof InitDataObject] = value as unknown as any;
      }
    });
    console.log("initDataObject", initDataObject);
    amplitude.init(
      import.meta.env.VITE_AMPLITUDE_API_KEY,
      `${initDataObject.user.id}`,
      {
        serverZone: "EU",
        autocapture: { elementInteractions: false, pageViews: false },
      },
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track = <T extends Record<string, string | any>>(event: string, data: T) => {
    amplitude.track(event, data);
  };

  trackTriumphZoneScreenViewed = () => {
    this.track(AmplitudeEvents.TriumphZoneScreenViewed, {
      screen_name: "triumph_zone",
    });
  };

  trackTriumphDrawViewed = (data: TriumphDrawViewedData) => {
    this.track(AmplitudeEvents.TriumphDrawViewed, data);
  };

  trackTriumphInsufficientTickets = (data: TriumphInsufficientTicketsData) => {
    this.track(AmplitudeEvents.TriumphInsufficientTickets, data);
  };

  trackTriumphRedirectToMoves = (data: TriumphRedirectToMovesData) => {
    this.track(AmplitudeEvents.TriumphRedirectToMoves, data);
  };

  trackTriumphTicketPurchased = (data: TriumphTicketPurchasedData) => {
    this.track(AmplitudeEvents.TriumphTicketPurchased, data);
  };

  trackTriumphParticipation = (data: TriumphParticipationData) => {
    this.track(AmplitudeEvents.TriumphParticipation, data);
  };

  trackTriumphResult = (data: TriumphResultData) => {
    this.track(AmplitudeEvents.TriumphResult, data);
  };

  trackMovesScreenViewed = (data: MovesScreenViewedData) => {
    this.track(AmplitudeEvents.MovesScreenViewed, data);
  };

  trackMoveSelected = (data: MoveSelectedData) => {
    this.track(AmplitudeEvents.MoveSelected, data);
  };

  trackMoveBetPlaced = (data: MoveBetPlacedData) => {
    this.track(AmplitudeEvents.MoveBetPlaced, data);
  };

  trackMoveResult = (data: MoveResultData) => {
    this.track(AmplitudeEvents.MoveResult, data);
  };

  trackMoveClosed = (data: MoveClosedData) => {
    this.track(AmplitudeEvents.MoveClosed, data);
  };

  trackCityTaskScreenViewed = (data: CityTaskScreenViewedData) => {
    this.track(AmplitudeEvents.CityTaskScreenViewed, data);
  };

  trackCityTaskStarted = (data: CityTaskStartedData) => {
    this.track(AmplitudeEvents.CityTaskStarted, data);
  };

  trackCityTaskCompleted = (data: CityTaskCompletedData) => {
    this.track(AmplitudeEvents.CityTaskCompleted, data);
  };

  trackCityTaskGoToBuilding = (data: CityTaskGoToBuildingData) => {
    this.track(AmplitudeEvents.CityTaskGoToBuilding, data);
  };

  trackDailyBonusScreenViewed = (data: DailyBonusScreenViewedData) => {
    this.track(AmplitudeEvents.DailyBonusScreenViewed, data);
  };

  trackDailyBonusClaimed = (data: DailyBonusClaimedData) => {
    this.track(AmplitudeEvents.DailyBonusClaimed, data);
  };

  trackDailyBonusError = (data: DailyBonusErrorData) => {
    this.track(AmplitudeEvents.DailyBonusError, data);
  };

  trackOnboardingScreenViewed = (data: OnboardingScreenViewedData) => {
    this.track(AmplitudeEvents.OnboardingScreenViewed, data);
  };

  trackOnboardingAction = (data: OnboardingActionData) => {
    this.track(AmplitudeEvents.OnboardingAction, data);
  };

  trackOnboardingCompleted = (data: OnboardingCompletedData) => {
    this.track(AmplitudeEvents.OnboardingCompleted, data);
  };
}

const amplitudeController = new AmplitudeController();

export { amplitudeController };
