import { ReactElement } from "react";
import { SuccessIcon } from "./icons";

import { ErrorIcon } from "./icons";

const iconsList = {
  success: SuccessIcon,
  error: ErrorIcon,
};

export const getNotifyIconByType = (
  type: "success" | "error",
): ReactElement | false => {
  return <img src={iconsList[type]} alt={type} />;
};
