import { createEvent, createStore, sample } from "effector";

import { coinsTap } from "./gameDomain";
import { StorageKeys } from "../module/app-storage/app-storage.constants";
import appStorage from "../module/app-storage";

interface OnboardState {
  clicksCount: number;
  droppedIncomeClicked: boolean;
  boostBlockClicked: boolean;
  tournamentClicked: boolean;
}

const defaultOnboardState: OnboardState = {
  clicksCount: 0,
  droppedIncomeClicked: false,
  boostBlockClicked: false,
  tournamentClicked: false,
};
const storageData = appStorage.getItem(
  StorageKeys.OnboardClicksCount,
) as unknown as OnboardState;

const initialDefaultOnboardState: OnboardState =
  typeof storageData === "object" ? storageData : defaultOnboardState;

export const toggleOnboardBoostBlockAnimate = createEvent();
export const toggleDroppedIncomeButtonClicked = createEvent();
export const toggleTournamentMenuClicked = createEvent();

export const $onboardClickeState = createStore(initialDefaultOnboardState);

const updateLocalState = (state: OnboardState) => {
  appStorage.setItem(StorageKeys.OnboardClicksCount, JSON.stringify(state));
};

export const initOnboard = () => {
  sample({
    source: $onboardClickeState,
    clock: coinsTap,
    filter: (value) => value.clicksCount < 51,
    fn: (value) => {
      const clicksCount = value.clicksCount + 1;
      return {
        ...value,
        clicksCount,
      };
    },
    target: $onboardClickeState,
  }).watch(updateLocalState);

  sample({
    source: $onboardClickeState,
    clock: toggleOnboardBoostBlockAnimate,
    filter: (value) => !value.boostBlockClicked,
    fn: (state) => {
      return {
        ...state,
        boostBlockClicked: true,
      };
    },
    target: $onboardClickeState,
  }).watch(updateLocalState);

  sample({
    source: $onboardClickeState,
    clock: toggleDroppedIncomeButtonClicked,
    filter: (value) => !value.droppedIncomeClicked,
    fn: (state) => {
      return {
        ...state,
        droppedIncomeClicked: true,
      };
    },
    target: $onboardClickeState,
  }).watch(updateLocalState);

  sample({
    source: $onboardClickeState,
    clock: toggleTournamentMenuClicked,
    filter: (value) => !value.tournamentClicked,
    fn: (state) => {
      return {
        ...state,
        tournamentClicked: true,
      };
    },
    target: $onboardClickeState,
  }).watch(updateLocalState);
};
