import { ReactNode } from "react";

import ReactDOM from "react-dom";

interface PortalRootI {
  children: ReactNode;
}

export const PortalRoot = (props: PortalRootI) => {
  const { children } = props;
  const root = document.querySelector("body") as HTMLBodyElement;
  return ReactDOM.createPortal(children, root);
};
