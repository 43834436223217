import { FC } from "react";

import {
  cssTransition,
  ToastContainer as DefaultToastContainer,
  ToastContainerProps,
} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./animations.css";
import { PortalRoot } from "../../../HOCS/PortalRoot";
import module from "./toast-containter.module.scss";

const transition = cssTransition({
  collapse: false,
  enter: "notify-enter",
  exit: "notify-exit",
});

export const ToastContainer: FC<ToastContainerProps> = () => {
  return (
    <PortalRoot>
      <DefaultToastContainer
        className={module.toastСontainerModifed}
        transition={transition}
        position="bottom-right"
        limit={3}
      />
    </PortalRoot>
  );
};
