import { toast } from "react-toastify";
import { ToastContent } from "./components/toast-content";
import { getNotifyIconByType } from "./utils";

interface Props {
  type: "success" | "error";
  message: string;
}

export const notify = (props: Props) => {
  const toastId = Math.random().toString(36).substr(2, 9);
  console.log(props);
  toast(
    () => {
      return (
        <ToastContent
          message={props.message}
          toastId={toastId}
          type={props.type}
        />
      );
    },
    {
      type: props.type,
      closeOnClick: true,
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeButton: false,
      icon: getNotifyIconByType(props.type),
    },
  );
};
