import { FC } from "react";
import module from "./toast-content.module.scss";
import classNames from "classnames";
interface Props {
  type: "success" | "error" | "info";
  message: string;
  toastId: string;
}

export const ToastContent: FC<Props> = ({ message, toastId, type }) => {
  return (
    <div
      id={toastId}
      className={classNames(
        module.toastContent,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        module[`toastContent-${type}`],
      )}
    >
      <span>{message}</span>
    </div>
  );
};
