export enum TournamentScope {
  All = "all",
  My = "my",
  Active = "active",
  Upcoming = "upcoming",
  Finished = "finished",
  NotFinished = "not_finished",
  Claimable = "claimable",
  OneOldOneActiveOneUpcomingPerType = "one_old_one_active_one_upcoming_per_type",
}

export enum TournamentType {
  DailyFree = "daily_free",
  DailyPaid = "daily_paid",
  WeeklyPaid = "weekly_paid",
}

export interface MainPageLotteriesList {
  [TournamentType.DailyFree]: TournamentListItem[];
  [TournamentType.DailyPaid]: TournamentListItem[];
  [TournamentType.WeeklyPaid]: TournamentListItem[];
}

export interface TicketsBalance {
  [TournamentType.DailyFree]: number;
  [TournamentType.DailyPaid]: number;
  [TournamentType.WeeklyPaid]: number;
}

export interface LotteriesIndexPayload {
  scopes: TournamentScope[];
  offset: number;
  limit: number;
  locale: string;
}

export enum TournamentStatus {
  Active = "active",
  Finished = "finished",
  Upcoming = "upcoming",
}

export interface TournamentListItem {
  id: string;
  title: string;
  endsAt: number;
  startsAt: number;
  imageUrl: string;
  prizeFund: number;
  type: TournamentType;
  status: TournamentStatus;
  entryPrice: number;
  userParticipated: boolean;
  userWon: boolean;
  claimable: boolean;
  userWonAmount?: string;
  userTicketsCount?: number;
}

export interface LotteriesIndexResponse {
  lotteries: TournamentListItem[];
  tickets: TicketsBalance;
  balance: string;
}

export interface TournamentWinner {
  ticketsCount: number;
  wonAmount: string;
  position: number;
  firstName: string;
  username: string;
  lastName: string;
  avatarId: string;
  avatarUrl: string;
}

export interface TournamentDetails {
  id: string;
  entryPrice: number;
  title: string;
  endsAt: number;
  startsAt: number;
  imageUrl: string;
  prizeFund: number;
  type: TournamentType;
  status: string;
  winnersSelected: boolean;
  claimable: boolean;
  userTicketsCount: number;
  userWonAmount: string;
  totalParticipantsCount: number;
  totalTicketsCount: number;
  winners: TournamentWinner[];
}

export interface TournamentDetailsResponse {
  lottery: TournamentDetails;
  tickets: TicketsBalance;
}

export interface WithdrawHistoryItem {
  id: string;
  status: string;
  amount: string;
  address: string;
  createdAt: number;
  txId: string;
  url: string;
  sentAt: number;
}

export interface WithdrawHistoryResponse {
  withdrawals: WithdrawHistoryItem[];
}
